<template>
  <div class="Social-profiles is-flex is-aligned-middle">
    <p
      :class="[{
        'Social-profiles--primary': primaryOnly,
        'Social-profiles--secondary': secondaryOnly
      }]"
    >
      <a
        v-for="profile in getProfiles"
        :key="profile.name"
        :href="getProfileValue(profile)"
        :class="[{
          'is-active': hasProfile(profile),
          'is-disabled': ! hasProfile(profile)
        }, profile.name]"
        target="_blank"
      >
        <v-icon
          v-if="primaryOnly"
          :type="profile.icon"
        />

        <round-icon
          v-if="secondaryOnly"
          :type="profile.icon"
          size=""
          bg-color="grey-darker"
          icon-class="hf-sm"
        />
      </a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    source: { type: Object, required: true },
    primaryOnly: { type: Boolean, default: false },
    secondaryOnly: { type: Boolean, default: false }
  },

  data: () => ({
    profiles: {
      primary: [
        { name: 'email', icon: 'email', object: 'emails', key: 'email', prefix: 'mailto:', gdprProtected: true },
        { name: 'phone', icon: 'cellphone', object: 'phones', key: 'phone', prefix: 'tel:', gdprProtected: true }
      ],
      secondary: [
        { name: 'facebook', icon: 'facebook', object: 'urls', key: 'url', gdprProtected: false },
        { name: 'instagram', icon: 'instagram', object: 'urls', key: 'url', gdprProtected: false },
        { name: 'linkedin', icon: 'linkedin', object: 'urls', key: 'url', gdprProtected: false },
        { name: 'twitter', icon: 'twitter', object: 'urls', key: 'url', gdprProtected: false },
        { name: 'youtube', icon: 'youtube', object: 'urls', key: 'url', gdprProtected: false },
        { name: 'website', icon: 'link', object: 'urls', key: 'url', gdprProtected: false }
      ]
    }
  }),

  computed: {
    getProfiles () {
      if (this.primaryOnly) {
        return this.profiles.primary
      }

      return this.profiles.secondary
    }
  },

  methods: {
    getProfileValue (profile) {
      const profileData = this.source[profile.object]

      if (!profileData || (Array.isArray(profileData) && profileData.length === 0)) {
        return null
      }

      let contacts = profileData[profile.name] || profileData

      if (!contacts || (contacts && contacts.length === 0)) {
        return null
      }

      // We only show the first contact, at the moment...
      const value = contacts[0]

      if (profile.prefix !== undefined) {
        return `${profile.prefix}${value}`
      }

      return value
    },

    hasProfile (profile) {
      const profileData = this.source[profile.object]

      if (profileData) {
        if (Array.isArray(profileData)) {
          return profileData.length > 0
        }

        return profileData[profile.name] && profileData[profile.name].length > 0
      }

      return false
    }
  }
}
</script>

<style lang="scss">
@import "~utils";

.Social-profiles {
  a {
    display: inline-block;
    opacity: 0.5;

    &.is-active {
      opacity: 1;
      color: $hf__color-primary;
    }

    &.is-disabled {
      cursor: default;
      color: $hf__color-secondary;
    }

    margin: 0 4px 4px 0;
    @include rtl {
      margin: 0 0 4px 4px;
    }
  }

  .Social-profiles--primary a {
    background: transparent;
    font-size: 1.3em;
  }
}
</style>
